<template>
    <div class="bg-slate-50 p-4 rounded-lg shadow-md col-start-1 col-end-13 flex justify-between items-center">
        <div class="flex flex-col">
            <div class="flex items-center relative w-max">
                <span class="font-medium text-gray-900 leading-4">Статус соединения</span>
                <svg v-if="status" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute ml-1 w-6 h-6 text-green-500 left-full">
                    <title>Соединено</title>
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="absolute ml-1 w-6 h-6 text-red-500 left-full">
                    <title>Соединение потеряно</title>
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                </svg>
            </div>
            <p class="text-sm text-gray-500 leading-4">
                <span class="font-medium">Пинг:</span>
                {{ status && mediaConnections.length ? connectionStats.ping / mediaConnections.length : 'н/д' }}
            </p>
            <p class="text-sm text-gray-500 leading-4">
                <span class="font-medium">Потеря пакетов:</span>
                {{ status && mediaConnections.length ? connectionStats.packetsLost / mediaConnections.length : 'н/д' }}
            </p>
        </div>
        <div>
            <span class="font-bold text-gray-900">{{ room ? room.name : 'Название комнаты'}}</span>
        </div>
        <div>
            <router-link to="/" title="На главную" class="block rounded-lg p-2 text-gray-900 hover:bg-blue-700 hover:text-white transition">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        room: Object,
        status: Boolean,
        mediaConnections: Object,
        roomId: String
    },
    data () {
        return {
            connectionStats: {
                ping: null,
                packetsLost: null,
            }
        }
    },
    mounted() {
        
        setInterval(() => {
            if(this.status) {
                this.getConnectionStats();
            }
        }, 5000);
    },
    methods: {
        getConnectionStats() {
            if(this.mediaConnections.length) {
                this.connectionStats.ping = 0;
                this.connectionStats.packetsLost = 0;

                this.mediaConnections.forEach(mConn => {
                    try {
                        mConn.peerConnection.getStats((report) => {
                            let rtcStatsReports = report.result();
        
                            for (let i = 0; i < rtcStatsReports.length; i++) {
                                let statNames = rtcStatsReports[i].names();
    
                                if (statNames.indexOf("transportId") > -1 && rtcStatsReports[i].stat('mediaType') == 'video') {
                                    this.connectionStats.ping += +rtcStatsReports[i].stat('googTargetDelayMs');
                                    this.connectionStats.packetsLost += +rtcStatsReports[i].stat('packetsLost');
                                }
                            }
                        })
                    } catch (error) {
                        //
                    }
                });
            }
        }
    }
}
</script>