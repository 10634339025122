<template>
    <app-header title="Настройки"/>
    <profile-block/>
    <devices-block/>
    <users-block/>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import ProfileBlock from '@/components/settings/ProfileBlock';
import DevicesBlock from '@/components/settings/DevicesBlock';
import UsersBlock from '@/components/settings/UsersBlock';

export default {
    components: {
        AppHeader,
        ProfileBlock,
        DevicesBlock,
        UsersBlock
    }
}
</script>