<template>
    <div 
        class="relative flex h-full flex-col bg-slate-50 col-start-7 col-end-10 row-start-5 row-end-7 shadow-md p-4 rounded-lg overflow-x-auto">
        <div v-if="deviceConnection" class="absolute top-0 left-0 w-full h-full bg-gray-100/50 flex flex-col justify-center items-center z-10">
            <svg aria-hidden="true" role="status" class="inline mb-2 w-10 h-10 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#2563eb"></path>
            </svg>
            <p class="text-sm font-medium text-gray-500">{{ deviceConnection == 'open' ? 'Включение' : 'Выключение' }} устройства...</p>
        </div>
        <div class="flex-[0_0_auto] inline-flex p-4 pl-0 text-sm font-medium text-gray-500 flex flex-wrap gap-2 items-center justify-between">
            Доступные устройства
            <div class="flex items-center">
                <span class="relative flex h-3.5 w-3.5 top-0 right-0 items-center justify-center">
                    <span class="absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75" :class="{ 'animate-ping !bg-red-400': videoList.find(v => v.isLocal == true) }"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-gray-500" :class="{ '!bg-red-500': videoList.find(v => v.isLocal == true) }"></span>
                </span>
                <span class="ml-2.5 font-extrabold text-lg text-gray-500" :class="{ '!text-red-500': videoList.find(v => v.isLocal == true) }">LIVE</span>
            </div>
        </div>
        <div class="flex-[1_1_auto]">
            <div class="flex flex-wrap gap-4">
                <main-btn
                    v-for="device in listDevices"
                    :key="device"
                    @click="toggleVideo(device)"
                    :disabled="deviceConnection"
                    class="bg-gray-400 h-max"
                    :class="{ '!bg-blue-700 hover:!bg-red-600 focus:!ring-red-300': activeDevices.some(key => key.deviceId == device.deviceId) }"
                >
                    {{ device.label }}
                </main-btn>
            </div>
        </div>
        <div class="flex-[0_0_auto] mt-4 border-t border-gray-200 pt-4">
            <main-btn @click="screenShare">
                Поделиться экраном
            </main-btn>
        </div>
    </div>
</template>

<script>
import { useUserStore as userStore } from '@/stores/user';
import { useNotificationStore as notifStore } from '@/stores/notification';
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        videoList: Array
    },
    data() {
        return {
            listDevices: [],
            activeDevices: [],
            deviceConnection: null
        }
    },
    emits: ['addStream', 'addNewCam', 'shareScreen', 'closeStream'],
    async mounted() {
        let devices = userStore().devices;
        
        if(!devices.length) {  
            if(navigator.mediaDevices) {
                devices = await navigator.mediaDevices.enumerateDevices();
            }
        }
         
        devices.forEach(el => {
            if(el.kind == 'videoinput') {
                this.listDevices.push(el);
            }
        });
    },
    methods: {
        async toggleVideo(device) {
            if(this.activeDevices.some(key => key.deviceId == device.deviceId)) {
                this.deviceConnection = 'close';

                let index = this.activeDevices.findIndex(key => key.deviceId == device.deviceId);
                let findDevice = this.activeDevices[index];
                this.activeDevices.splice(index, 1);

                this.$emit('closeStream', findDevice);
            } else {
                this.deviceConnection = 'open';

                let constraints = {
                    audio: false,
                    video: {
                        // facingMode: "environment",
                        // aspectRatio: { exact: 1.7777777778 },
                        width: { min: 600, ideal: 1920, max: 1920 },
                        height: { min: 400, ideal: 1080, max: 1080 },
                        deviceId: { exact: device.deviceId },
                    }
                };
    
                let localStream = null;

                try {
                    localStream = await navigator.mediaDevices.getUserMedia(constraints);
                } catch (error) {
                    try {
                        localStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: { deviceId: { exact: device.deviceId } } });
                    } catch (error) {
                        try {
                            localStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId: { exact: device.deviceId } } });
                        } catch (error) {
                            notifStore().add('warning', 5000, 'При переключении устройства произошла ошибка.');
                            this.deviceConnection = null;
                        }
                    }
                }

                if(localStream) {
                    this.activeDevices.push({
                        'deviceId': device.deviceId,
                        'kind': device.kind,
                        'tracks': localStream.getTracks()
                    });
                    
                    this.$emit('addStream', localStream, true, userStore().user.role == '2');
                    this.$emit('addNewCam', localStream);
                }
            }

            this.deviceConnection = null;
        },
        screenShare() {
            this.$emit('shareScreen');
        }
    },
    components: {
        MainBtn
    }
}
</script>