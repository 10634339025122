<template>
    <div class="bg-slate-50 shadow-md rounded-lg col-start-9 col-end-13 row-start-2 row-end-5 p-4 overflow-y-auto">
        <div class="flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
            Управление пользователями
        </div>
        <main-btn class="mb-4 !py-2" @click="showCreate">
            Создать пользователя
        </main-btn>
        <div class="flex flex-col gap-1 overflow-y-auto" :class="{ 'h-full max-h-[175px]': showForm }">
            <div v-for="user in listUsers" :key="user" class="flex items-center rounded-lg p-1 justify-between hover:bg-gray-200">
                <div class="flex items-center gap-2">
                    <div class="bg-blue-500 text-white rounded-lg flex justify-center items-center p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>
                    </div>
                    <span class="font-medium text-sm text-gray-900">
                        {{ user.name }} <span class="font-light">({{ user.role == 1 ? 'Стойка' : 'Доктор' }})</span> <br>
                        <span class="text-gray-500 font-normal">({{ user.id }})</span>
                    </span>
                </div>
                <div class="flex item-center justify-center">
                    <a @click="showEdit(user)" class="w-5 mr-2 text-gray-900 hover:text-blue-500 hover:scale-110 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </a>
                    <a @click="removeUser(user.id)" class="w-5 mr-2 text-gray-900 hover:text-red-600 hover:scale-110 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <user-form v-model:show="showForm" :user="editUser" @getUsers="getUsers"/>
    </div>
</template>

<script>
import axios from 'axios';
import UserForm from '@/components/settings/UserForm';
import MainBtn from '@/components/MainBtn';

export default {
    data () {
        return {
            user: [],
            listUsers: [],
            showForm: false,
            editUser: null
        }
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        async getUsers() {
            await axios.get('/users').then(res => {
                this.listUsers = res.data;
            }).catch(() => {
                this.listUsers = [];
            });
        },
        showCreate() {
            if(this.showForm && this.editUser) {
                this.editUser = null;
            } else {
                this.editUser = null;
                this.showForm = !this.showForm;
            }
        },
        showEdit(user) {
            if((this.showForm && !this.editUser) || (this.showForm && this.editUser && this.editUser !== user)) {
                this.editUser = user;
            } else {
                this.editUser = user;
                this.showForm = !this.showForm;
            }
        },
        removeUser(id) {
            if(confirm('Вы действительно хотите удалить этого пользователя?')) {
                console.log(id);
            }
        }
    },
    components: {
        UserForm,
        MainBtn
    }
}
</script>