<template>
    <div 
        class="bg-slate-50 col-start-10 col-end-13 row-start-2 row-end-4 shadow-md p-4 rounded-lg overflow-x-auto">
        <div class="grid grid-rows-2 gap-4"
            style="grid-template-rows: 48px 1fr;">
            <img src="@/assets/logo.svg" class="w-[125px] h-16" alt="logo">
            <div>
                <p>{{ (mainVideo && mainVideo.label ? mainVideo.label : 'Главный экран') }}</p>
            </div>
            <div class="space-y-4">
                <range-input
                    id="zoom"
                    label="Приблизить (zoom)"
                    v-model="settings.zoom"
                    min="1"
                    max="5"
                    step="0.1"
                    :disabled="!mainVideo"
                />
                <range-input
                    id="tX"
                    label="Ось X"
                    v-model="settings.tX"
                    :min="getMinTranlate('x')"
                    :max="getMaxTranlate('x')"
                    step="1"
                    :disabled="!mainVideo"
                />
                <range-input
                    id="tY"
                    label="Ось Y"
                    v-model="settings.tY"
                    :min="getMinTranlate('y')"
                    :max="getMaxTranlate('y')"
                    step="1"
                    :disabled="!mainVideo"
                />
            </div>
        </div>
    </div>
</template>

<script>
import RangeInput from '@/components/form/RangeInput';

export default {
    props: {
        mainStream: Object,
        mainVideo: Object
    },
    emits: ['update:mainStream'],
    data () {
        return {
            settings: this.mainStream
        }
    },
    methods: {
        getMaxTranlate(axis) {
            let param = this.getMainVideoSize(axis);

            return Math.round((((param * this.settings.zoom) - param) / this.settings.zoom) / 2)
        },
        getMinTranlate(axis) {
            let param = this.getMainVideoSize(axis);

            return -Math.round((((param * this.settings.zoom) - param) / this.settings.zoom) / 2)
        },
        getMainVideoSize(axis) {
            let el = document.querySelector('#mainVideo video');

            return !el ? 0 : axis == 'x' ? el.offsetWidth : el.offsetHeight;
        }
    },
    watch: {
        'settings.zoom': function() {
            this.settings.tX = 0;
            this.settings.tY = 0;
        }
    },
    components: {
        RangeInput
    }
}
</script>