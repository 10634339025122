<template>
    <div class="bg-slate-50 shadow-md overflow-hidden rounded-lg col-start-1 col-end-7 row-start-2 row-end-5 p-4">
        <div class="inline-flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
            </svg>
            Список комнат
        </div>
        <div class="overflow-y-auto h-full">
            <div v-for="room in rooms" :key="room" class="flex items-center justify-between block px-4 py-2 border-b border-gray-200 w-full rounded-lg hover:bg-gray-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                <p class="text-gray-900 text-sm">
                    Комната <span class="font-medium">«{{ room.name }}»</span>
                </p>
                <main-btn :to="`/room/${room.id}`" class="!py-2">
                    Войти
                </main-btn>
            </div>
        </div>
        <div v-if="!rooms.length" class="text-center text-sm font-medium block px-4 py-2 border-t border-b border-gray-200 w-full rounded-lg hover:bg-gray-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
            Комнаты не найдены
        </div>
    </div>
</template>

<script>
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        rooms: Array
    },
    emits: ['getRooms'],
    mounted() {
        this.$emit('getRooms');
    },
    components: {
        MainBtn
    }
}
</script>