<template>
    <div 
        class="bg-slate-50 shadow-md p-4 rounded-lg col-start-10 col-end-13 row-start-4 row-end-7 grid grid-rows-6 gap-4"
        style="grid-template-rows: 54px repeat(4, 1fr) 54px;">
        <div class="border-b border-gray-200">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
                <li class="mr-2">
                    <a @click="section = 'chat'"
                        class="inline-flex p-4 rounded-t-lg border-b-2 hover:text-gray-600 hover:border-gray-300 group cursor-pointer"
                        :class="{ '!border-blue-600 !text-blue-600 hover:text-blue-600 hover:border-blue-600': section == 'chat'}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                        </svg>
                        Чат
                    </a>
                </li>
                <li class="mr-2">
                    <a @click="section = 'files'"
                        class="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 group cursor-pointer"
                        :class="{ '!border-blue-600 text-blue-600 hover:text-blue-600 hover:border-blue-600': section == 'files'}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                        </svg>
                        Файлы
                    </a>
                </li>
                <li class="mr-2">
                    <a @click="section = 'users'"
                        class="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 group cursor-pointer"
                        :class="{ '!border-blue-600 text-blue-600 hover:text-blue-600 hover:border-blue-600': section == 'users'}">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                        </svg>
                        Участники
                    </a>
                </li>
            </ul>
        </div>

        <block-chat ref="blockChat" :show="section == 'chat'" :roomId="roomId"/>
        <block-files :show="section == 'files'"/>
        <block-users :show="section == 'users'" :roomUsers="room" :roomId="roomId" :peers="peers"/>
    </div>
</template>

<script>
import BlockChat from '@/components/room/chat/BlockChat';
import BlockFiles from '@/components/room/chat/BlockFiles';
import BlockUsers from '@/components/room/chat/BlockUsers';

export default {
    props: {
        roomId: String,
        room: Object,
        peers: Array
    },
    data() {
        return {
            section: 'chat'
        }
    },
    methods: {
        addMessage(message) {
            this.$refs.blockChat.chat.push({
                type: message.type,
                userId: message.user.id,
                text: message.text
            });
        }
    },
    components: {
        BlockChat,
        BlockFiles,
        BlockUsers
    }
}
</script>