<template>
    <div class="bg-slate-50 shadow-md overflow-hidden rounded-lg col-start-1 col-end-5 row-start-2 row-end-5 p-4 overflow-y-auto">
        <div class="inline-flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
            Информация обо мне
        </div>
        <form @submit.prevent="update">
            <div class="flex flex-wrap gap-2">
                <text-input :value="userStore.user.id" label="ID" disabled/>
                <text-input :value="userStore.userRole" label="Роль" disabled/>
                <text-input v-model="form.name" label="Имя" id="name" required/>
                <text-input v-model="form.email" label="Email" type="email" id="email" required/>
                <text-input v-model="form.password" label="Пароль" type="password" id="password"/>
                <text-input v-if="form.password" v-model="form.confirmPaasword" label="Подтверждение пароля" type="password" id="confirmPaasword" required/>
                <text-input :value="userStore.user.createdAt" label="Дата регистрации" disabled/>
            </div>
            <main-btn>Обновить информацию</main-btn>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUserStore as userStore } from '@/stores/user';
import { useNotificationStore as notifStore } from '@/stores/notification';
import TextInput from '@/components/form/TextInput';
import MainBtn from '@/components/MainBtn';

export default {
    data () {
        return {
            userStore: userStore(),
            form: {
                name: userStore().user.name,
                email: userStore().user.email,
                password: null,
                confirmPaasword: null
            }
        }
    },
    methods: {
        update() {
            if(this.form.password && this.form.password == this.form.confirmPaasword) {
                axios.post('/user', this.form).then(res => {
                    notifStore().add('success', 5000, 'Информация успешно обновлена');
                    let user = JSON.parse(userStore.user);
                    let resUser = res.data;
                    user.name = resUser.name
                    user.email = resUser.email;

                    Cookies.set('user', user);
                }).catch(res => {
                    if(res.response.data.errors) {
                        res.response.data.errors.forEach(error => {
                            notifStore().add('error', 5000, error.msg);
                        });
                    } else {
                        notifStore().add('error', 5000, 'При обновлении информации произошла ошибка.');
                    }
                })
            } else {
                notifStore().add('error', 5000, 'Введёные пароли не совпадают.');
            }
        }
    },
    components: {
        TextInput,
        MainBtn
    }
}
</script>