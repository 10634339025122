<template>
    <div v-if="show" class="border-t border-gray-200 mt-4">
        <div class="inline-flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            {{ user ? 'Редактирование' : 'Создание' }} пользователя
        </div>
        <form @submit.prevent="user ? update() : store()">
            <div class="flex flex-wrap gap-2 mt-3">
                <select-input v-model="form.role" label="Роль пользователя" id="user" required>
                    <option value="">Выберите роль</option>
                    <option value="1">Стойка</option>
                    <option value="2">Доктор</option>
                </select-input>
                <text-input v-model="form.name" label="Имя" placeholder="Введите имя" id="name" required/>
                <text-input v-model="form.email" type="email" label="E-mail" placeholder="Введите email" id="email" required/>
                <text-input v-model="form.password" type="password" label="Пароль" placeholder="Введите пароль" id="password" required/>
                <text-input v-model="form.confirmPassword" type="password" label="Подтверждение пароля" placeholder="Подтверждение пароля" id="confirmPassword" required/>
            </div>
            <main-btn class="ml-auto">{{ user ? 'Сохранить' : 'Создать' }}</main-btn>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { useNotificationStore as notifStore } from '@/stores/notification';
import TextInput from '@/components/form/TextInput';
import SelectInput from '@/components/form/SelectInput';
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        show: Boolean,
        user: Object
    },
    emits: ['update:show', 'getUsers'],
    data () {
        return {
            form: {
                role: '',
                name: null,
                email: null,
                password: null,
                confirmPassword: null
            }
        }
    },
    mounted() {
        if(this.user) {
            this.form.role = this.user.role;
            this.form.name = this.user.name;
            this.form.email = this.user.email;
        }
    },
    watch: {
        user(val) {
            if(val) {
                this.form.role = this.user.role;
                this.form.name = this.user.name;
                this.form.email = this.user.email;
            } else {
                this.form.role = '';
                this.form.name = null;
                this.form.email = null;
            }
        }
    },
    methods: {
        store() {
            if(this.form.password !== this.form.confirmPassword ) {
                notifStore().add('error', 5000, 'Введённые пароли не совпадают.');
            } else {
                axios.post('/auth/registration', this.form)
                    .then(() => {
                        notifStore().add('success', 5000, 'Пользователь успешно создан.');
                        this.$emit('update:show', false);
                        this.$emit('getUsers');
                    }).catch((res) => {
                        if(res.response.data.errors) {
                            res.response.data.errors.forEach(error => {
                                notifStore().add('error', 5000, error.msg);
                            });
                        } else {
                            notifStore().add('error', 5000, 'При создании пользователя произошла ошибка, проверьте правильность введённых данных.');
                        }
                    });
            }
        },
        update() {
            // update user
        }
    },
    components: {
        TextInput,
        SelectInput,
        MainBtn
    }
}
</script>