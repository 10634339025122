<template>
    <app-header title="Главная"/>
    <list-room @getRooms="getRooms" :rooms="rooms"/>
    <create-room @getRooms="getRooms"/>
</template>

<script>
import axios from 'axios';
import AppHeader from '@/components/AppHeader';
import ListRoom from '@/components/home/ListRoom';
import CreateRoom from '@/components/home/CreateRoom';

export default {
    data() {
        return {
            rooms: []
        }
    },
    methods: {
        async getRooms() {
            await axios.get('/user/rooms').then(res => {
                this.rooms = res.data;
            });
        }
    },
    components: {
        AppHeader,
        ListRoom,
        CreateRoom
    }
}
</script>