<template>
    <div v-if="screenshot" class="flex p-2 overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-4 left-0 right-0 md:inset-0 z-[10000000000] justify-center items-center">
        <div @click="close" class="fixed inset-0 h-full w-full z-30 bg-gray-300 opacity-50"></div>
        <div class="relative w-full max-w-4xl bg-white rounded-lg shadow-md p-4 z-40">
            <div class="flex items-start justify-between border-b rounded-t">
                <h3 class="text-gray-900 font-semibold">
                    Скриншот окна
                </h3>
                <button @click="close" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="default-modal">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
                </button>
            </div>
            <div class="pb-2">
                <img :src="screenshot" class="w-full object-cover rounded-lg"/>
            </div>
            <div class="flex justify-between gap-2 items-center border-t border-gray-200 rounded-b">
                <main-btn @click="close">
                    Закрыть
                </main-btn>
                <main-btn :href="screenshot" download="screenshot">
                    Сохранить
                </main-btn>
            </div>
        </div>
    </div>
</template>

<script>
import MainBtn from '@/components/MainBtn';

export default {
    data() {
        return {
            screenshot: null
        }
    },
    methods: {
        getScreen(video) {
            if (video !== null) {
                let canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                this.screenshot = canvas.toDataURL('image/jpeg');
            }
        },
        close() {
            this.screenshot = null;
        }
    },
    components: {
        MainBtn
    }
}
</script>