<template>
    <div 
        class="bg-slate-50 shadow-md p-4 rounded-lg col-start-1 col-end-4 row-start-5 row-end-7 overflow-x-auto">
        <p class="inline-flex p-4 pl-0 text-sm font-medium text-gray-500">Локальные настройки</p>
        <div class="space-y-4">
            <template v-for="(param, key) in videoParams" :key="key">
                <range-input
                    v-if="mainVideo && mainVideo.capabilities[key]"
                    :id="key"
                    :label="param"
                    :value="mainVideo.settings[key]"
                    :min="mainVideo.capabilities[key].min"
                    :max="mainVideo.capabilities[key].max"
                    :step="mainVideo.capabilities[key].step"
                    @change="changeParam($event.target.value, key)"
                />
                <range-input
                    v-else
                    :label="`${param} (недоступно)`"
                    disabled
                />
            </template>
            <div class="grid grid-cols-2 gap-4">
                <checkbox-input label="Микрофон" v-model="toggleAudio" @change="toggleState('audio')" :disabled="!videoList.find(v => v.isLocal == true)"/>
                <checkbox-input label="Камера"  v-model="toggleVideo" @change="toggleState('video')" :disabled="!videoList.find(v => v.isLocal == true)"/>
            </div>
        </div>
    </div>
</template>

<script>
import RangeInput from '@/components/form/RangeInput';
import CheckboxInput from '@/components/form/CheckboxInput';

export default {
    props: {
        mainVideo: [Object, Array],
        videoList: Array
    },
    data () {
        return {
            videoParams: {
                frameRate: 'Частота кадров',
                brightness: 'Яркость',
                contrast: 'Контраст',
                saturation: 'Насыщенность',
                sharpness: 'Резкость'
            },
            toggleVideo: true,
            toggleAudio: true
        }
    },
    emits: ['changeConstraints'],
    methods: {
        changeParam(val, param) {
            this.$emit('changeConstraints', val, param);
        },
        toggleState(type) {
            this.videoList.forEach(async video => {
                if(video.isLocal) {
                    let track = type == 'audio' ? await video.stream.getAudioTracks()[0] : await video.stream.getVideoTracks()[0];
                    
                    if(track) {
                        track.enabled = type == 'audio' ? this.toggleAudio : this.toggleVideo;
                    }
                }
            });
        }
    },
    components: {
        RangeInput,
        CheckboxInput
    }
}
</script>