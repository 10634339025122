<template>
  <div class="relative h-full" @mouseover="controls = true" @mouseleave="controls = false">
    <video
      ref="videoRef"
      class="w-full h-full object-cover rounded-lg shadow-md"
      :id="video.id"
      :style="videoSettings"
      :muted="muted"
      autoplay
      @dblclick="setMain"
    >
    </video>
    <div class="absolute bottom-6 left-6 space-y-2 opacity-0 transition ease-in-out delay-100 custom-video-controls" :class="{'opacity-100': controls}">
      <div class="bg-white shadow-md rounded-full cursor-pointer w-10 h-10 p-2" @click="fillWindow()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
        </svg>
      </div>

      <div class="bg-white shadow-md rounded-full cursor-pointer w-10 h-10 p-2" @click="playButton()">
        <svg v-if="playPause == 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-0.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
        </svg>
      </div>
      <div class="bg-white shadow-md rounded-full cursor-pointer w-10 h-10 p-2" @click="mutedButton()">
        <svg v-if="speaker == 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" />
        </svg>
      </div>
      <div class="bg-white shadow-md rounded-full cursor-pointer w-10 h-10 p-2" @click="getScreenshot()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>
      </div>
      <div class="bg-white shadow-md rounded-full cursor-pointer w-10 h-10 p-2" @click="startRecord()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
        </svg>
      </div>
    </div>
    <div v-if="recording" class="absolute top-4 right-4 flex items-center">
        <span class="relative flex h-3 w-3 top-0 right-0 items-center justify-center">
            <span class="absolute animate-ping inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500"></span>
        </span>
        <span class="ml-2.5 font-bold text-base text-red-500">REC</span>
    </div>
  </div>
</template>

<script>
import { useNotificationStore as notifStore } from '@/stores/notification';

export default {
  props: {
    muted: {
      type: Boolean,
      default: true
    },
    video: Object,
    settings: Object
  },
  emits: ['setMainVideo', 'getScreenshot'],
  data () {
    return {
      controls: false,
      playPause: 1,
      speaker: 1,
      fullWindow: false,
      recording: false,
      mediaRecorder: null,
      chunks: []
    }
  },
  mounted() {
    if(this.video) {
      this.$refs.videoRef.srcObject = this.video.stream;
      setTimeout(() => {
        if(this.$refs.videoRef) {
          this.$refs.videoRef.play();
        }
      }, 250);
    }
  },
  computed: {
    videoSettings() {
      return this.video.mainVideo ? { transform: `scale(${this.settings ? this.settings.zoom : 1}) translate(${this.settings ? this.settings.tX : 0}px, ${this.settings ? this.settings.tY : 0}px)` } : {};
    }
  },
  methods: {
    setMain() {
      this.$emit('setMainVideo');
    },
    fillWindow() {
      if(this.fullWindow) {
        document.exitFullscreen();
      } else {
        this.$refs.videoRef.requestFullscreen();
      }
    },
    playButton() {
      if(this.$refs.videoRef.paused || this.$refs.videoRef.ended) {
        this.$refs.videoRef.play();
        this.playPause = 1;
      } else {
        this.$refs.videoRef.pause();
        this.playPause = 0;
      }
    },
    mutedButton() {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.speaker = !this.$refs.videoRef.muted;
    },
    getScreenshot() {
      this.$emit('getScreenshot', this.$refs.videoRef);
    },
    startRecord() {
      try {
        if(!this.recording) {
          this.recording = true;
          this.mediaRecorder = new MediaRecorder(this.video.stream)
          this.mediaRecorder.ondataavailable = e => this.pushRecordData(e)
          this.mediaRecorder.onstop = () => this.onStopRecord()
          this.mediaRecorder.start();
          notifStore().add('success', 2500, 'Началась запись выбранного экрана.');
        } else {
          this.recording = false;
          this.mediaRecorder.stop();
        }
      } catch (error) {
        notifStore().add('success', 2500, 'При запуске или получении записи произошла ошибка.');
      }
    },
    onStopRecord () {
      var blob = new Blob(this.chunks, { 'type' : 'video/webm' });
      this.chunks = [];
      const file = new File ([blob], 'broadcast-recording.webm', { 'type' : 'video/webm' })
      var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = 'broadcast-recording.webm';
        document.body.appendChild(a);
        a.click()
    },
    pushRecordData (e) {
      this.chunks.push(e.data);
    }
  }
}
</script>