<template>
    <header-room :room="room" :status="connectionStatus" :mediaConnections="mediaConnections"/>
    <div 
        class="col-start-1 col-end-4 row-start-2 row-end-5 grid gap-4 overflow-y-auto">
        
        <template v-for="(video, key) in videoList" :key="key">
            <VideoEmbed
                v-if="!video.mainVideo && !video.doctor"
                class="max-h-[330px]"
                ref="videos"
                :video="video"
                :muted="video.muted"
                @setMainVideo="setMainVideo(key)"
                @getScreenshot="getScreenshotVideo"
            />
        </template>
        <img
            v-if="videoList.filter(v => v.mainVideo == false && v.doctor == false).length <= 0 || !videoList.length"
            class="shadow-md rounded-lg max-h-[330px] w-full h-full object-cover"
            src="@/assets/default-video.jpg"
            alt="no-video"
        >
        <img
            v-if="videoList.filter(v => v.mainVideo == false && v.doctor == false).length <= 1 || !videoList.length"
            class="shadow-md rounded-lg max-h-[330px] w-full h-full object-cover"
            src="@/assets/default-video.jpg"
            alt="no-video"
        >
    </div>
    <div 
        class="bg-slate-50 shadow-md overflow-hidden rounded-lg col-start-4 col-end-10 row-start-2 row-end-5">
        <template v-for="video in videoList" :key="video">
            <VideoEmbed
                v-if="(video.mainVideo == true && video.doctor == false)"
                id="mainVideo"
                ref="videos"
                :muted="video.muted"
                :video="video"
                :settings="mainStream"
                @getScreenshot="getScreenshotVideo"
            />
        </template>
        <img v-if="!videoList.filter(v => v.mainVideo == true && v.doctor == false ).length" class="w-full h-full object-cover" src="@/assets/default-video.jpg" alt="no-video">
    </div>
    
    <room-information v-model:mainStream="mainStream" :mainVideo="mainVideo"/>
    <stream-settings :videoList="videoList" :mainVideo="mainVideo" @changeConstraints="changeConstraints"/>

    <template v-for="video in videoList" :key="video">
        <VideoEmbed
            v-if="video.doctor == true"
            class="col-start-4 col-end-7 row-start-5 row-end-7 shadow-md rounded-lg"
            ref="videos"
            :muted="video.muted"
            :video="video"
            @getScreenshot="getScreenshotVideo"
        />
    </template>
    <img v-if="!videoList.filter(v => v.doctor == true).length" class="col-start-4 col-end-7 row-start-5 row-end-7 shadow-md rounded-lg w-full h-full object-cover" src="@/assets/default-video.jpg" alt="no-video">

    <device-list :videoList="videoList" @addStream="addStream" @addNewCam="addNewCam" @shareScreen="shareScreen" @closeStream="closeStream"/>
    <room-chat ref="chat" :room="room" :roomId="roomId" :peers="peers"/>
    <screenshot-modal ref="screenshotModal"/>
</template>

<script>
import axios from 'axios';
import { Peer } from "peerjs";
import { io } from "socket.io-client";
import { useUserStore } from '@/stores/user';
import { useNotificationStore as notifStore } from '@/stores/notification';
import VideoEmbed from '@/components/VideoEmbed';
import HeaderRoom from '@/components/room/HeaderRoom';
import RoomInformation from '@/components/room/RoomInformation';
import StreamSettings from '@/components/room/StreamSettings';
import DeviceList from '@/components/room/DeviceList';
import RoomChat from '@/components/room/RoomChat';
import ScreenshotModal from '@/components/room/ScreenshotModal';

export default {
    props: {
        socketURL: String,
        peerHost: String,
        roomId: String
    },
    data () {
        return {
            room: null,
            socket: null,
            myPeer: null,
            connectionStatus: false,
            peers: [],
            videoList: [],
            mediaConnections: [],
            mainStream: {
                zoom: 1,
                tX: 0,
                tY: 0
            }
        }
    },
    computed: {
        mainVideo() {
            return this.videoList.find(v => v.mainVideo == true);
        }
    },
    mounted() {
        this.connectionInit();

        setInterval(() => {
            if(this.mediaConnections.length) {
                this.mediaConnections.forEach(mediaConnection => {
                    // if (mediaConnection.peerConnection.iceConnectionState === 'failed') {
                        mediaConnection.peerConnection.createOffer({iceRestart:true});
                    // }
                });
            }

            if(!this.connectionStatus) {
                console.log("ПОПЫТКА СОЕДИНЕНИЯ");
                this.connectionInit();
            }
        }, 5000);
    },
    methods: {
        test() {
            // console.log(this.videoList.filter(v => v.mainVideo == false && v.doctor == false).length);
            // console.log(await this.mediaConnections[0].peerConnection.getStats());
            // console.log(this.ping);
            console.log(this.mediaConnections[0].peerConnection);
        },
        connectionInit() {
            this.socket = io(this.socketURL);
    
            this.myPeer = new Peer(useUserStore().user.id, {
                host: this.peerHost,
                path: '/peerjs',
                secure: true,
                port: 443
            });

            this.myPeer.on('call', (call, stream) => { // Когда мы присоединяемся к чьей-то комнате, мы получаем от них звонок
                let user = call.peer;
                let doctor = false;
                
                if(useUserStore().user.role == '1') {
                    if(this.room.users) {
                        this.room.users.forEach(r => {
                            if(r.user.id == user && r.user.role == '2') {
                                doctor = true;
                            }
                        });
                    }
                }

                this.mediaConnections.push(call);
                call.answer(stream) // Транслируйте им наше видео/аудио
                
                call.on('stream', userVideoStream => { // Когда мы получаем их поток
                    this.addStream(userVideoStream, false, doctor) // Показываем их видео себе
                })
            });

            this.myPeer.on('error', () => {
                this.connectionStatus = false;
                this.leave();
            });
            
            this.myPeer.on('open', id => {
                this.connectionStatus = true;
                
                this.socket.emit('join-room', this.roomId, id);
                this.getPeers();
            });
            
            this.socket.on('user-connected', (userId, stream) => { // Если новый пользователь подключается
                this.getRoomInfo();

                this.getPeers();
                setTimeout(this.connectToNewUser, 1000, userId, stream);
            });

            this.socket.on("user-disconnected", (userId) => {
                console.log("disconnetct - " + userId);
                this.peers.splice(this.peers.indexOf(userId), 1);

                this.mediaConnections.forEach((mConn, index) => {
                    if(mConn.peer == userId) {
                        this.mediaConnections.splice(index, 1);
                    }
                })
            });

            this.socket.on('message', (message) => {
                this.$refs.chat.addMessage(message);
            });

            this.getRoomInfo();

            setInterval(() => {
                if(this.peers.length < 2) {
                    this.checkActiveStream();
                }
            }, 60000);
        },
        setMainVideo(index) {
            this.videoList.forEach(video => {
                video.mainVideo = false;
            });

            this.videoList[index].mainVideo = true;
            this.mainStream.zoom = 1;
            // this.mainStream = { zoom: 1, tX: 0, tY: 0 }
        },
        getScreenshotVideo(video) {
            this.$refs.screenshotModal.getScreen(video);
        },
        async getRoomInfo() {
            await axios.get(`/rooms/${this.roomId}`).then(res => {
                this.room = res.data;
            });
        },
        closeStream(devices) {
            this.videoList.forEach((v, i) => v.stream.getTracks().forEach(video => {
                devices.tracks.forEach(el => {
                    if(video.id == el.id) {
                        video.stop();
                        
                        if(el.kind == 'video') {
                            this.videoList.splice(i, 1);
                        }
                    }
                });
            }));
        },
        async changeConstraints(val, param) {
            const [videoTrack] = this.mainVideo.stream.getVideoTracks();
            const settings = videoTrack.getSettings();
            const constraints = {advanced: [{[param]: val}]};

            if(param in settings && val !== settings[param]) {
                await videoTrack.applyConstraints(constraints);
            }
        },
        leave() {
            this.mediaConnections.forEach(connect => {
                connect.close();
            });
            // this.videoList.forEach(v => v.stream.getTracks().forEach(t => t.stop()));
            // this.videoList = [];
            if(this.myPeer) {
                this.myPeer.destroy();
                this.myPeer = null;
            }
            if(this.socket) {
                this.socket.destroy();
                this.socket = null;
            }
        },
        async addNewCam(stream) {
            const [videoTrack] = stream.getVideoTracks();
            // const capabilities = videoTrack.getCapabilities();
            const settings = videoTrack.getSettings();
            
            if('exposureMode' in settings) {
                await videoTrack.applyConstraints({advanced: [{['exposureMode']: 'manual'}]});
            }

            await this.getPeers();
            
            this.peers.forEach(peer => {
                if(peer != this.myPeer.id) {
                    this.myPeer.call(peer, stream);
                }
            });
        },
        connectToNewUser(userId, stream) {
            this.videoList.forEach(video => {
                if(video.isLocal == true) {
                    const call = this.myPeer.call(userId, video.stream);
                    //Добавить их видео
                    call.on('stream', userVideoStream => {
                        this.addStream(userVideoStream, false)
                    });
                    // Если они уйдут, удалите их видео
                    call.on('close', () => {
                        console.log("ЗАКРЫВАЕМ СТРИМ");
                        this.removeStream(stream);
                    });
                }
            });
        },
        addStream(stream, isLocal, doctor = false) {
            const [videoTrack] = stream.getVideoTracks();
            const capabilities = videoTrack.getCapabilities();
            const settings = videoTrack.getSettings();

            let found = this.videoList.find(video => {
                return video.id === stream.id
            })

            if (found === undefined) {
                let video = {
                    id: stream.id,
                    muted: isLocal,
                    stream: stream,
                    isLocal: isLocal,
                    capabilities: capabilities,
                    settings: settings,
                    mainVideo: this.videoList.find(v => v.mainVideo == true && v.doctor == false) ? false : true,
                    doctor: doctor
                };
                this.videoList.push(video);
            }

            let that = this;

            setTimeout(function () {
                for (let i = 0, len = that.$refs.videos.length; i < len; i++) {
                    if (that.$refs.videos[i].id === stream.id) {
                        that.$refs.videos[i].srcObject = stream;
                        break;
                    }
                }
            }, 500);
        },
        removeStream(stream) {
            let found = this.videoList.find(video => {
                return video.id === stream.id
            })

            console.log(found);
        },
        async shareScreen() {
            try {
                let screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
                this.addStream(screenStream, true);
                this.addNewCam(screenStream);
            } catch (error) {
                notifStore().add('warning', 5000, 'Произошла ошибка или действие было отменено пользователем.');
            }
        },
        async getPeers() {
            if(this.myPeer) {
                await this.myPeer.listAllPeers(data => {
                    this.peers = data;
                })
            }
        },
        checkActiveStream() {
            this.videoList.forEach((v, i) => v.stream.getTracks().forEach(t => {
                if(t.muted && this.peers.length < 2) {
                    this.videoList.splice(i, 1); 
                }
            }));
        }
    },
    beforeUnmount() {
        this.leave();
    },
    components: {
        HeaderRoom,
        RoomInformation,
        StreamSettings,
        DeviceList,
        VideoEmbed,
        RoomChat,
        ScreenshotModal
    }
}
</script>