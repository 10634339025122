<template>
    <div>
        <label
            v-if="label"
            :for="id"
            class="block text-sm font-medium text-gray-900"
        >
            {{ label }}
        </label>
        <input
            id="default-range"
            ref="range-input"
            type="range"
            v-bind="{ ...$attrs, class: null }"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            :value="modelValue ?? $attrs.value"
            @input="change($event)"
        >
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        label: String,
        modelValue: [String, Number],
    },
    data() {
        return {
            inputType: this.type
        }
    },
    emits: ['update:modelValue'],
    methods: {
        change(e) {
            this.$emit('update:modelValue', e.target.value)
        }
    }
}
</script>