<template>
    <div v-if="notifications.length" class="fixed top-4 right-[5px] w-full max-w-[400px] flex flex-col gap-3 z-[100]">
        <template v-for="(notification, index) in notifications" :key="notification">
            <div
                v-if="index < 5"
                class="relative w-full shadow rounded-md bg-slate-800 px-3 py-2 pr-8 text-sm font-medium text-white flex items-center gap-1"
                :class="{'!bg-red-400': notification.type == 'error', '!bg-orange-400': notification.type == 'warning', '!bg-blue-400': notification.type == 'info', '!bg-emerald-400': notification.type == 'success'}"
            >
                <svg v-if="notification.type == 'error' || notification.type == 'warning'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="26" height="26" class="min-w-[26px] mr-1">
                    <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                </svg>

                <svg v-if="notification.type == 'info'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="26" height="26" class="min-w-[26px] mr-1">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
                </svg>

                <svg v-if="notification.type == 'success'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="26" height="26" class="min-w-[26px] mr-1">
                    <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                </svg>
                
                {{ notification.text }}
                <a @click="close(index)" class="cursor-pointer absolute top-2 right-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </a>
            </div>
        </template>
    </div>
</template>

<script>
import { useNotificationStore } from '../stores/notification'

export default {
    data() {
        return {
            notificationStore: useNotificationStore(),
            notifications: []
        }
    },
    methods: {
        close(index) {
            this.notifications.splice(index, 1);
        }
    },
    watch: {
        'notificationStore.notifications': {
            handler(val) {
                this.notifications = val;
            },
            immediate: true,
            deep: true
        }
    }
}
</script>