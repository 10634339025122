<template>
    <div class="bg-slate-50 shadow-md overflow-hidden rounded-lg col-start-5 col-end-9 row-start-2 row-end-5 p-4 overflow-y-auto">
        <div class="flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>
            Настройка устройств
        </div>
        <main-btn class="!py-2" @click="getDevices()">
            Получить список устройств
        </main-btn>

        <div class="p-4 pl-0 text-sm font-medium text-gray-500">
            Сохраненные устройства
        </div>
        <div class="flex flex-col gap-2">
            <div v-for="(device, index) in userDevices"
                :key="index"
                class="flex items-center justify-between gap-2 rounded-lg p-1 hover:bg-gray-200"
            >
                <div class="flex items-center gap-2">
                    <div class="bg-blue-500 text-white rounded-lg flex justify-center items-center p-2">
                        <svg v-if="device.kind == 'videoinput'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <svg v-if="device.kind == 'audioinput'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                        </svg>
                    </div>
                    <span class="font-medium text-sm text-gray-900">{{ device.label }} <br> <span class="text-gray-500 font-normal">{{ device.defaultLabel }}</span></span>
                </div>
                <a @click="removeDevice(index)" class="w-5 mr-2 text-gray-900 hover:text-red-600 hover:scale-110 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </a>
            </div>
            <div v-if="!userDevices.length" class="rounded-lg p-2 hover:bg-gray-200 text-center font-medium text-sm text-gray-900">
                Устройства не найдены
            </div>
        </div>

        <div v-if="deviceSearch" class="flex flex-col justify-center items-center">
            <svg aria-hidden="true" role="status" class="inline mb-2 w-10 h-10 text-gray-200 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#2563eb"></path>
            </svg>
            <p class="text-sm font-medium text-gray-500">Поиск устройств...</p>
        </div>

        <template v-if="newDevices.length">
            <div class="flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
                Новые устройства
            </div>
            <div class="flex flex-col gap-2">
                <div v-for="device in newDevices"
                    :key="device"
                    @click="selectDevice = device"
                    class="flex items-center gap-2 rounded-lg p-1 hover:bg-gray-200 cursor-pointer"
                    title="Добавить"
                >
                    <div class="bg-green-500 text-white rounded-lg flex justify-center items-center p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </div>
                    <span class="font-medium text-sm text-gray-900">{{ device.label }} <br> <span class="text-gray-500 font-normal">({{ device.kind == 'videoinput' ? 'Видео' : 'Аудио' }})</span></span>
                </div>
            </div>
        </template>
    </div>
    <div v-if="selectDevice" class="fixed w-full h-full overflow-hidden top-0 left-0 flex justify-center items-center bg-gray-300/50 p-2">
        <div @click="cancelAdd" class="fixed w-full h-full"></div>
        <div class="relative bg-slate-50 rounded-lg show-md p-4 w-full max-w-[800px] z-40">
            <div class="p-4 pl-0 text-sm font-medium text-gray-500">
                Добавление устройства
            </div>
            <form @submit.prevent="addDevice">
                <text-input v-model="labelNewDevice" placeholder="Придумайте имя устройства" id="labelNewDevice" required/>
                <div class="flex flex-wrap items-center justify-between gap-2">
                    <main-btn @click="cancelAdd">
                        Отмена
                    </main-btn>
                    <main-btn type="submit">
                        Сохранить
                    </main-btn>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { useUserStore as userStore } from '@/stores/user';
import TextInput from '@/components/form/TextInput';
import MainBtn from '@/components/MainBtn';

export default {
    data() {
        return {
            userDevices: userStore().devices,
            newDevices: [],
            selectDevice: null,
            labelNewDevice: null,
            deviceSearch: false
        }
    },
    methods: {
        async getDevices() {
            this.deviceSearch = true;
            this.newDevices = [];

            const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
            
            enumerateDevices.forEach(async el => {
                if(el.deviceId != 'default' && el.deviceId != 'communications') {
                    if(el.kind == 'videoinput') {
                        await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: el.deviceId } } }).then(() => {
                            if(!this.userDevices.find(d => d.deviceId == el.deviceId) && !this.newDevices.find(d => d.deviceId == el.deviceId)) {
                                this.newDevices.push(el);
                            }
                        }).catch(() => { })
                    } else if(el.kind == 'audioinput') {
                        await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: el.deviceId } } }).then(() => {
                            if(!this.userDevices.find(d => d.deviceId == el.deviceId) && !this.newDevices.find(d => d.deviceId == el.deviceId)) {
                                this.newDevices.push(el);
                            }
                        }).catch(() => { })
                    }
                }
            });

            this.deviceSearch = false;
        },
        addDevice() {
            let index = this.newDevices.indexOf(d => d.deviceId == this.selectDevice.deviceId);

            this.userDevices.push({
                'deviceId': this.selectDevice.deviceId,
                'defaultLabel': this.selectDevice.label,
                'label': this.labelNewDevice,
                'kind': this.selectDevice.kind
            });

            Cookies.set('devices', JSON.stringify(this.userDevices));
            this.selectDevice = null;
            this.labelNewDevice = null;

            if(index) {
                this.newDevices.splice(index, 1);
            }
        },
        removeDevice(index) {
            if(confirm('Вы действительно хотите удалить это устройство?')) {    
                let upDevices = this.userDevices;
    
                upDevices.splice(index, 1);
                Cookies.set('devices', JSON.stringify(this.userDevices));
            }
        },
        cancelAdd() {
            this.selectDevice = null;
            this.labelNewDevice = null;
        }
    },
    components: {
        TextInput,
        MainBtn
    }
}
</script>