import { defineStore } from 'pinia'
import axios from 'axios'
import Cookies from 'js-cookie'
import router from '@/router'
import { useNotificationStore as notifStore } from './notification'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {},
        devices: Cookies.get('devices') ? JSON.parse(Cookies.get('devices')) : []
    }),

    getters: {
        userRole() {
            if(this.user) {
                if(this.user.role == 1) {
                    return 'Стойка';
                }
                if(this.user.role == 2) {
                    return 'Доктор';
                }
            }
        }
    },

    actions: {
        logout() {
            Cookies.remove('user');
            window.location.href = '/login';
        },
      
        async login(email, password) {
            let data = [];
            
            let login = await axios.post('/auth/login', { email: email, password: password }).then(res => {
                data = res.data;

                let auth = this.checkAuth(data.token).then(res => {
                    let user = {
                        id: res.data.id,
                        role: res.data.role,
                        name: res.data.name,
                        email: res.data.email,
                        token: data.token,
                        createdAt: res.data.createdAt,
                        updatedAt: res.data.updatedAt,
                        isLoggedIn: true
                    };
    
                    this.user = user;
    
                    Cookies.set('user', JSON.stringify(user));

                    return true;
                });

                return auth;
            }).catch(res => {
                if(res.response) {
                    res.response.data.errors.forEach(error => {
                        notifStore().add('error', 5000, error.msg);
                    });
                } else {
                    notifStore().add('error', 5000, 'Ошибка авторизации, проверьте корректность введенных данных.');
                }
            });

            if(login) {
                notifStore().add('success', 5000, 'Вы успешно авторизовались.');
                router.push({ name: 'home' });
            }
        },

        checkAuth(token) {
            return axios.get('/auth/user', { headers: { 'Authorization': `Bearer ${token}` } }).catch(() => {
                notifStore().add('warning', 5000, 'Токен авторизации недействителен или отсутствует.');
                Cookies.remove('user');
                router.push({ name: 'login' });
            });
        }
    },
})