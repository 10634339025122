<template>
    <div v-if="show" ref="usersBody" class="h-full overflow-y-auto row-start-2 row-end-6 p-1">
        <div class="flex flex-col gap-3">
            <div class="flex items-center gap-2">
                <div class="bg-green-500 text-white rounded-lg flex justify-center items-center p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                </div>
                <span class="font-medium text-sm">{{ user.name }} (Вы)</span>
            </div>
            <template v-for="member in roomUsers.users" :key="member">
                <div v-if="member.user.id != user.id" class="flex items-center gap-2">
                    <div class="bg-blue-500 text-white rounded-lg flex justify-center items-center p-2" :class="{ '!bg-green-500': peers.includes(member.user.id) }">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>
                    </div>
                    <span class="font-medium text-sm">{{ member.user.name }}</span>
                    <main-btn @clikc="kick(member.user.id)" class="ml-auto bg-red-500 hover:!bg-red-600 focus:!ring-red-300 !py-2">
                        Исключить
                    </main-btn>
                </div>
            </template>
        </div>
    </div>
    <form v-if="show" @submit.prevent="invite" class="row-start-6 inline-flex">
        <select-input v-model="inviteUser" id="user" required>
            <option value="">Выберите пользователя</option>
            <template v-for="user in users" :key="user">
                <option v-if="!roomUsers.users.find(u => u.user.id == user.id)" :value="user.id">{{ user.name }}</option>
            </template>
        </select-input>
        <main-btn type="submit" class="ml-2" title="Пригласить пользователя">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
            </svg>
        </main-btn>
    </form>
</template>

<script>
import axios from 'axios';
import { useUserStore } from '@/stores/user';
import { useNotificationStore as notifStore } from '@/stores/notification';
import SelectInput from '@/components/form/SelectInput';
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        show: Boolean,
        roomId: String,
        roomUsers: Object,
        peers: Array
    },
    data() {
        return {
            user: useUserStore().user,
            inviteUser: '',
            users: []
        }
    },
    async mounted() {
        await axios.get('/users').then(res => {
            this.users = res.data;
        });
    },
    methods: { 
        async invite() {
            await axios.post('/rooms/invite', { userId: this.inviteUser, roomId: this.roomId })
                .then(res => {
                    if(res) {
                        notifStore().add('success', 5000, 'Пользователь успешно приглашен в комнату.');
                        this.inviteUser = '';
                    }
                }).catch(() => {
                    notifStore().add('error', 5000, 'При приглашении произошла ошибка.');
                });
        },
        kick(id) {
            console.log(id);
        }
    },
    watch: {
        'show'() {
            setTimeout(() => {
                if(this.$refs.chatBody) {
                    this.$refs.chatBody.scrollTo(0, this.$refs.chatBody.scrollHeight)
                }
            }, 100);
        }
    },
    components: {
        SelectInput,
        MainBtn
    }
}
</script>