<template>
    <div
        class="h-screen p-4 grid grid-rows-6 grid-cols-12 gap-4"
        style="grid-template-rows: 80px repeat(5, 1fr);"
    >
        <app-notification/>
        <slot/>
    </div>
</template>
<script>
import AppNotification from '@/components/AppNotification';

export default {
    components: {
        AppNotification
    }
}
</script>