<template>
    <app-layout>
        <router-view></router-view>
    </app-layout>
</template>

<script>
import AppLayout from './layouts/AppLayout';

export default {
    name: 'App',
    components: {
        AppLayout
    },
    mounted() {
        if(navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                .catch(() => {
                    navigator.mediaDevices.getUserMedia({ audio: false, video: true }).catch(() => {
                        //
                    });
                });
        }
    }
}
</script>