<template>
    <div class="bg-slate-50 shadow-md overflow-hidden rounded-lg col-start-7 col-end-13 row-start-2 row-end-5 p-4">
        <div class="inline-flex p-4 pl-0 text-sm font-medium text-gray-500 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                <path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
            </svg>
            Создание комнаты
        </div>
        <form @submit.prevent="createRoom">
            <text-input v-model="roomName" label="Имя комнаты" placeholder="Введите имя комнаты" id="roomName" required/>
            <main-btn>Создать</main-btn>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { useNotificationStore as notifStore } from '@/stores/notification';
import TextInput from '@/components/form/TextInput';
import MainBtn from '@/components/MainBtn';

export default {
    data() {
        return {
            roomName: null,
        }
    },
    emits: ['getRooms'],
    methods: {
        createRoom() {
            axios.post('/rooms/create', { name: this.roomName })
                .then(res => {
                    if(res) {
                        notifStore().add('success', 5000, 'Комната успешно создана.');
                        this.roomName = null;
                        this.$emit('getRooms');
                    }
                }).catch(res => {
                    if(res.response) {
                        res.response.data.errors.forEach(error => {
                            notifStore().add('error', 5000, error.msg);
                        });
                    } else {
                        notifStore().add('error', 5000, 'При создании комнаты произошла ошибка.');
                    }
                });
        }
    },
    components: {
        TextInput,
        MainBtn
    }
}
</script>