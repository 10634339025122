<template>
    <button v-if="!$attrs.href && !to"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 focus:outline-none text-center inline-flex items-center cursor-pointer"
        v-bind="{ ...$attrs }"
    >
        <slot/>
    </button>
    <router-link v-else-if="to"
        :to="to"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 focus:outline-none text-center inline-flex items-center cursor-pointer"
        v-bind="{ ...$attrs }"
    >
        <slot/>
    </router-link>
    <a v-else
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-4 focus:outline-none text-center inline-flex items-center cursor-pointer"
        v-bind="{ ...$attrs }"
    >
        <slot/>
    </a>
</template>

<script>
export default {
    props: {
        to: String
    },
}
</script>