<template>
    <web-rtc 
        ref="webrtc"
        :socketURL="socketURL"
        :peerHost="peerHost"
        :roomId="roomId"
    />
</template>

<script>
import WebRtc from '@/components/WebRtc';

export default {
    data() {
        return {
            socketURL: `https://${process.env.VUE_APP_API_URL}`,
            peerHost: process.env.VUE_APP_API_URL,
            roomId: this.$route.params.id
        }
    },
    components: {
        WebRtc
    }
}
</script>