<template>
    <div v-if="show" ref="filesBody" class="h-full overflow-y-auto row-start-2 row-end-6">
        <div class="flex flex-wrap">
            <a v-for="file in files" :key="file" :href="file.url" class="min-w-[150px] relative block p-2 flex-[1_1_150px] cursor-pointer text-gray-500 hover:bg-blue-500 hover:text-white rounded-md">
                <div class="relative flex justify-center items-center w-max ml-[-8px]">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-14">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                    <span class="absolute mt-1.5 text-sm">{{ file.format }}</span>
                </div>
                <p class="font-light text-sm">{{ file.name }}</p>
                <p class="font-bold text-sm">{{ file.size }}</p>
            </a>
        </div>
    </div>
    <form v-if="show" @submit.prevent="attach" class="row-start-6 inline-flex">
        <div @click="$refs.filesInput.showPicker()"
            :class="{ 'mb-2': attachedFiles.length }"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
        >
            <span v-if="!attachedFiles.length">Нажмите чтобы прикрепить файлы</span>
            <span v-else>Прикреплено файлов: {{ attachedFiles.length }}</span>
            <input @change="selectFile" ref="filesInput" id="files" type="file" class="hidden" multiple/>
        </div>
        <main-btn type="submit" class="ml-2" title="Отправить файлы">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
            </svg>
        </main-btn>
    </form>
</template>

<script>
// import axios from 'axios';
import { useNotificationStore as notifStore } from '@/stores/notification';
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        show: Boolean
    },
    data() {
        return {
            files: [{
                            'url': 'url',
                            'name': 'name.docx',
                            'format': 'jpeg',
                            'size': '150 MB'
                        },
                        {
                            'url': 'url',
                            'name': 'name.docx',
                            'format': 'jpeg',
                            'size': '150 MB'
                        }],
            attachedFiles: []
        }
    },
    methods: { 
        attach() {
            if(this.attachedFiles.length) {
                console.log('attach');
            } else {
                notifStore().add('warning', 5000, 'Необходимо выбрать файлы')
            }
        },
        selectFile(e) {
            this.attachedFiles = e.target.files;
        }
    },
    watch: {
        'show'() {
            setTimeout(() => {
                if(this.$refs.filesBody) {
                    this.$refs.filesBody.scrollTo(0, this.$refs.filesBody.scrollHeight)
                }
            }, 100);
        }
    },
    components: {
        MainBtn
    }
}
</script>