import axios from 'axios'
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import AuthView from '../views/AuthView'
import SettingsView from '../views/SettingsView'
import RoomView from '../views/RoomView'
import { useUserStore as userStore } from '@/stores/user'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
    meta: { auth: false }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: { auth: true }
  },
  {
    path: '/room/:id',
    name: 'room',
    component: RoomView,
    meta: { auth: true }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = userStore().user ? userStore().user.token : '';
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  let auth = to.matched.some(record => record.meta.auth);

  if(auth) {
    userStore().checkAuth(token);
  }

  if(!auth && userStore().user && userStore().user.isLoggedIn) {
    next({
      name: 'home'
    });
  } else if(auth && !userStore().user) {
    next({
      name: 'login'
    });
  } else {
    next();
  }
})

export default router
