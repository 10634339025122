<template>
    <main class="col-start-1 col-end-13 row-start-1 row-end-5 grid gap-4">
        <div class="flex justify-center h-max mt-4">
            <div class="bg-slate-50 relative w-full max-w-3xl px-4 h-full md:h-auto shadow-md rounded-lg p-4">
                <div class="flex items-center gap-5 mb-4">
                    <h2 class="text-2xl font-bold tracking-tight text-gray-600">Авторизация</h2>
                </div>
                <form @submit.prevent="auth">
                    <text-input v-model="email" label="E-mail" placeholder="Введите email" id="email" required/>
                    <text-input v-model="password" type="password" label="Пароль" placeholder="Введите пароль" name="password" autocomplete="on" id="password" required/>
                    <main-btn :disabled="authProcess">Войти</main-btn>
                </form>
            </div>
        </div>
    </main>
</template>

<script>
import { useUserStore } from '@/stores/user';
import TextInput from '@/components/form/TextInput';
import MainBtn from '@/components/MainBtn';

export default {
    data () {
        return {
            email: null,
            password: null,
            authProcess: false
        }
    },
    methods: {
        async auth() {
            this.authProcess = true;
            const user = useUserStore();

            await user.login(this.email, this.password);
            this.authProcess = false;
        }
    },
    components: {
        TextInput,
        MainBtn
    }
}
</script>