<template>
    <div class="mb-6 flex-[1_1_300px]" :class="$attrs.class">
        <label v-if="label" class="block tracking-wide text-sm font-medium text-gray-900 mb-2" :for="id">{{ label }}</label>
        <select
            :id="id" 
            ref="input"
            v-model="selected"
            v-bind="{ ...$attrs, class: null }"
            class="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500"
        >
            <slot />
        </select>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
        },
        label: String,
        modelValue: [String, Number, Boolean, Object, Array],
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selected: this.modelValue,
        }
    },
    watch: {
        selected(selected) {
            this.$emit('update:modelValue', selected)
        },
        modelValue(val) {
            this.selected = val;
        }
    }
}
</script>