import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notifications: [],
    }),

    actions: {
        add(type, duration, text) {
            let id = Math.random().toString(16).slice(2)+(new Date()).getTime()+Math.random().toString(16).slice(2);

            this.notifications.push({
                id: id,
                type: type,
                duration: duration,
                text: text
            });

            setTimeout(() => {
                let index = this.notifications.findIndex(p => p.id == id);

                if(index >= 0) {
                    this.notifications.splice(index, 1);
                }
            }, duration);
        }
    },
})