<template>
    <div v-if="show" ref="chatBody" class="h-full px-4 py-6 flex flex-col space-y-4 p-3 overflow-y-auto row-start-2 row-end-6">
        <template v-for="message in chat" :key="message">
            <div v-if="message.type == 'system'" class="info-message flex justify-center items-center text-center">
                <div class="flex-grow border-t border-gray-500"></div>
                <span class="mx-2 text-gray-700 text-sm font-medium">{{ message.text }}</span>
                <div class="flex-grow border-t border-gray-500"></div>
            </div>
            <div v-else-if="message.userId !== user.id" class="chat-message">
                <div class="flex items-end">
                    <div class="flex flex-col space-y-2 text-xs max-w-sm mx-2 order-2 items-start">
                        <div class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                            <span class="block font-bold text-sm">{{ message.sender }}</span>
                            {{ message.text }}
                        </div>
                    </div>
                    <div class="w-9 h-9 bg-orange-400 text-white rounded-full flex items-center justify-center text-base order-1">
                        AА
                    </div>
                </div>
            </div>
            <div v-else class="chat-message">
                <div class="flex items-end justify-end">
                    <div class="flex flex-col space-y-2 text-xs mx-2 order-1 items-end">
                        <div class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-500 text-white">
                            <span class="block font-bold text-sm">{{ user.name }}</span>
                            <p>{{ message.text }}</p>
                        </div>
                    </div>
                    <div class="w-9 h-9 min-w-[36px] min-h-[36px] bg-orange-400 text-white rounded-full flex items-center justify-center text-base order-2">
                        ИВ
                    </div>
                </div>
            </div>
        </template>
    </div>
    <form v-if="show" @submit.prevent="send" class="row-start-6 inline-flex">
        <text-input v-model="message" placeholder="Введите сообщение" id="message" required/>
        <main-btn type="submit" class="ml-2" title="Отправить сообщение">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
            </svg>
        </main-btn>
    </form>
</template>

<script>
import axios from 'axios';
import { useUserStore } from '@/stores/user';
import TextInput from '@/components/form/TextInput';
import MainBtn from '@/components/MainBtn';

export default {
    props: {
        show: Boolean,
        roomId: String
    },
    data() {
        return {
            user: useUserStore().user,
            chat: [],
            message: null
        }
    },
    mounted() {
        axios.post('/messages', { roomId: this.roomId }).then(res => {
            this.chat = res.data;
        });
    },
    methods: { 
        send() {
            axios.post('/messages/create', { type: 'message', roomId: this.roomId, text: this.message });
            this.message = null;
        },
        addSystemMessage(message) {
            axios.post('/messages/create', { type: 'system', roomId: this.roomId, text: message });
        },
        invite() {
            console.log('invite');
        }
    },
    watch: {
        'show'() {
            setTimeout(() => {
                if(this.$refs.chatBody) {
                    this.$refs.chatBody.scrollTo(0, this.$refs.chatBody.scrollHeight)
                }
            }, 100);
        },
        'chat.length'() {
            setTimeout(() => {
                if(this.$refs.chatBody) {
                    this.$refs.chatBody.scrollTo(0, this.$refs.chatBody.scrollHeight)
                }
            }, 100);
        }
    },
    components: {
        TextInput,
        MainBtn
    }
}
</script>